import './App.scss';
import LeftSide from './components/leftSide'
import CenterMain from './components/centerMain'
import RightTags from './components/rightTags'
import { MenuOutlined,CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useToggleMenu from './hooks/toggleMenu';
import useToggleTags from './hooks/toggleTags';
import { useEffect } from 'react';
import eventBus from './eventBus';
function App() {
  const [isOpenMenu,toggleMenu] = useToggleMenu(false)
  const [isOpenTags,toggleTags] = useToggleTags()
  const leftOpen = isOpenMenu?`open-left`:''
  const rightOpen = isOpenTags?`open-right`:''
  const toggleMenuOnly = () =>{
    console.log('toggleMenuOnly')
    if(isOpenTags){
      toggleTags()
    }
    toggleMenu()
  }
  const toggleTagsOnly = () =>{
    console.log('toggleTagsOnly')
    if(isOpenMenu){
      toggleMenu()
    }
    toggleTags()
  }
  const closeTags = () =>{
    isOpenTags&&toggleTags()
  }
  useEffect(()=>{
    console.log('app mounted')
    eventBus.on('toggleTagsOnly',toggleTagsOnly)
    return ()=>{
      
      eventBus.off('toggleTagsOnly',toggleTagsOnly)
    }
  },[])
  return (
    <div className="app">
      <header className='app-header'>
        <Button icon={isOpenMenu?<CloseOutlined />:<MenuOutlined />} onClick={toggleMenuOnly}></Button>
        <Button icon={isOpenTags?<CloseOutlined />:<MenuOutlined />} onClick={toggleTagsOnly}></Button>
      </header>
      <main className="app-main flex-row">
        <aside className={`left ${leftOpen}`} >
          <LeftSide></LeftSide>
        </aside>
        <div className="center" onClick={closeTags}>
          <CenterMain></CenterMain>
        </div>
        <div className={`right ${rightOpen}`} >
          <RightTags></RightTags>
        </div>
      </main>
    </div>
  );
}

export default App;
