import React from "react";
import './index.scss'
export default function Tag({tag}:{tag:Tag}){
  return (
    <div className="tag p-d-t-30 p-d-b-30 p-d-l-22 p-d-r-22 m-g-b-30 ">
      <div className="tag-title semibold f-600 m-g-b-15">{tag?.title}</div>
      <div className="tag-content m-g-b-10">{tag?.content}</div>
      <div className="tag-update-time f-12">{tag?.updateTime}</div>
    </div>
  )
}