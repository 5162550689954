import { RefObject, useEffect, useRef, useState } from 'react'
import './index.scss'
import eventBus from '../../eventBus'

// import Draft from './components/quill'

import {Draft} from './components/editor';
// import BundledEditor from './components/tinymce';
export default function CenterMain() {
  const contentRef = useRef<HTMLDivElement>(null)
  const [mainContent,setMainContent] = useState('')
  useEffect(()=>{
    eventBus.on('startCreateCategory',startCreateCategory)
    eventBus.on('resetMainContent',data=>resetMainContent(data))
    return ()=>{
    eventBus.off('resetMainContent',resetMainContent)
    eventBus.off('startCreateCategory',startCreateCategory)
    }
  },[])
  useEffect(()=>{
    console.log('mainContent:',mainContent)
  },[mainContent])
  const startCreateCategory = () =>{
    console.log('startcreate')
    setMainContent('')
  }
  const resetMainContent = (data:string) => {
    console.log(data)
    setMainContent('asdf')
  }
  return (
    <div className="center-main">
      <div className='main-content  m-g-b-10'>
        <Draft mainContent={mainContent} setMainContent={setMainContent}></Draft>
      </div>
    </div>
  )
}
