import './index.scss'
import Tag from './components/tag'
import { useEffect ,useState} from 'react'
import eventBus from '../../eventBus'
export default function RightTags() {
  const [tags,setTags] = useState<Tags>({list:[],total:0})
  useEffect(()=>{
    queryTagList()
    return ()=>{

    }
  },[])
  const queryTagList = () =>{
    const data:Tags = {
      total:1,
      list:[
        {
          id:'',
          title:'dd',
          content:'contentcontentcontentcontentcontentcontentcontentcontentcontentcontentcontentcontent',
          updateTime:'2020-12-12 11:11'
        },
      ]
    }
    setTags(data)
  }
  return (
    <div className='right-tags p-d-t-10'>
      {tags.list.map((t:Tag)=><Tag tag={t} key={`tag-${t.id}`}></Tag>)}
    </div>
  )
}
