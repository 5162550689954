import { useState, useCallback } from "react";

const useLoading = (initialState = false): [Boolean, (state:boolean) => void] => {
  const [isLoading, setIsLoading] = useState(initialState)

  const setLoading = useCallback((state:boolean) => {
    setIsLoading(() => state)
  }, [])
  
  return [isLoading, setLoading]
}

export default useLoading