import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import './index.scss'
import { Input, Button, Space, Modal,Popover } from 'antd'
import { SendOutlined,SelectOutlined,CheckOutlined} from '@ant-design/icons';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import eventBus from '../../../../eventBus';
const { TextArea } = Input
const Inline = Quill.import('blots/inline');

class TagBlot extends Inline {
  static create(tagid) {
    let node = super.create();
    node.setAttribute('href', '#');
    node.setAttribute('tagid', tagid);
    return node;
  }
  
  static formats(node) {
    return node.getAttribute('tagid');
  }
}
TagBlot.blotName = 'tag';
TagBlot.tagName = 'a';
TagBlot.className = 'quill-popover';
Quill.register(TagBlot);

export function Draft({mainContent,setMainContent}) {
  const AIBtnRef = useRef()
  const tagBtnRef = useRef()
  const editorRef = useRef()
  const [editorState, setEditorState] = useState()
  const [selectedText, setSelectedText] = useState('')
  const [range, setRange] = useState({ index: 0, length: 0 })
  const [showTool, setShowTool] = useState(false)
  const [toolTop, setToolTop] = useState(0)
  const [askOpen, setAskOpen] = useState(false);
  const [tagOpen,setTagOpen] = useState(false)
  const [tagContent,setTagContent] = useState('')
  const [askLock,setAskLock] = useState(false)
  const [questionContent, setQuestionContent] = useState('')
  const [isAnswering,setIsAnswering] = useState(true)
  const [answerContent, setAnswerContent] = useState('')
  useEffect(() => {
    const editor = editorRef.current
    if (editor) {
      setEditorState(editor)
    }
    return () => {
    }
  }, [])
 
  useEffect(() => {
    if (editorState) {
      const quill = editorState.getEditor();
      insertHtmlText();
      quill.root.addEventListener('click',function(e){
        if(e.target.className === 'quill-popover'){
          e.stopPropagation();
          console.log(e.target)
          const nodeId = e.target.getAttribute('tagid')
          console.log('nodeId:',nodeId)
          eventBus.emit('toggleTagsOnly')
          setTagOpen(true)
        }
      })
      quill.on('selection-change', (range, oldRange, source) => {
        console.log('range:', range)
        console.log('oldRange:', oldRange)
        console.log('source:', source)
        console.log(quill)
        // setToolTop
        if (range) {
          setRange(range)
          var text = quill.getText(range.index, range.length);
          setSelectedText(text)
          var bounds = quill.getBounds(range.index, range.length);
          var editorElement = document.querySelector('#editor');
          var editorRect = editorElement.getBoundingClientRect();
          var relativeX = bounds.left - editorRect.left;  // 相对于编辑器的X位置
          var relativeY = bounds.top - editorRect.top;    // 相对于编辑器的Y位置
          console.log('选中文本相对于编辑器的X位置:', relativeX);
          console.log('选中文本相对于编辑器的Y位置:', relativeY);
        }
        
      })
      quill.on('text-change',(delta, oldDelta, source) => {
        console.log('delta:', delta)
        console.log('oldDelta:', oldDelta)
        console.log('source:', source)
        setSelectedText('')
        // 无内容时按空格键唤起ai询问弹窗
        if(delta.ops?.[0]?.insert === ' '){
          askAI()
        }
      })
    }
    console.log(editorState)
  }, [editorState])
  useEffect(() => {
    setShowTool(!!selectedText)
  }, [selectedText])
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean'], // remove formatting button
  ];
  const insertHtmlText = () => {
    const htmlText = mainContent
    // const htmlText = '<p>第一节<a class="quill-popover" href="#" tagid="123">aaaa</a>中间节<a class="quill-popover" href="#"   tagid="456">bbbbb</a>末尾节<br />换行 ，\n 再换，<a class="quill-popover" href="#"  tagid="789">标签</a></a></p>';
    const quill = editorState?.getEditor()
    if(quill){
      // 使用 clipboard 模块的 dangerouslyPasteHTML 方法插入 HTML 文本
      quill.clipboard.dangerouslyPasteHTML(htmlText,'silent');
    }
  };
  const editorStateChange = (e) => {
    console.log(e)
    setMainContent(e)
  }
  const editorBlur = (e) => {
    console.log(e)
    const { index, length } = e
    setShowTool(!!length)
  }
  const submitQuestion = () => {
    setAskLock(true)
    console.log('questionContent:', questionContent)
    setTimeout(()=>{
      getAIAnswer()
    },2000)
  }
  const submitTag = () => {
    console.log('tag content:',tagContent)
    setTagOpen(false)
  }
  const getAIAnswer = () =>{
    const aiAnswer = '这是ai回答的结果，\n换行，空格 ，'
    const answerList = aiAnswer.split('')
    insertText2Answer(answerList[0],0,answerList)

  }
  const insertText2Answer = (text,index,list) =>{
    if(index<=list.length){
      setIsAnswering(true)
      setAnswerContent(pre=>pre+text)
      setTimeout(()=>{
        insertText2Answer(list[index+1]||'',index+1,list)
      },200)
    }
    else{
      setIsAnswering(false)
    }
  }
  const insertAnswer2Editor = () =>{
    console.log('answerContent:',answerContent)
    const quill = editorState.getEditor()
    console.log('editorState:',editorState)
    console.log('text:',editorState.value)
    quill.insertText(range.index+range.length,['<p> </p>','<p><br></p>'].includes(editorState.value)?answerContent:`\n${answerContent}`)
    initAIDialog()
  }
  const initAIDialog = () =>{
    setAnswerContent('')
    setQuestionContent('')
    setAskLock(false)
    setAskOpen(false)
  }
  const initTagDialog = () =>{
    setTagContent('')
    // eventBus.emit('toggleTagsOnly')
  }
  const askAI = () => {
    AIBtnRef.current.click()
  }
  const askAIHandle = (e) => {
    setAskOpen(true)
    setQuestionContent(selectedText)
  }
  const tag = () => {
    tagBtnRef.current.click()
  }
  const tagHandle = () =>{
    const quill = editorState.getEditor()
    const tagid = `tag-${new Date().getTime()}`
    quill.format('tag', tagid);
  }
  const CustomToolbar = useCallback(({ style }) => (
    <div id="toolbar" style={{ position: 'absolute', zIndex: 9, background: '#fff', ...style }}>
      <button className="ql-askAI" title='问问AI...'>
        AI
      </button>
      <button className="ql-tag" style={{ width: '40px' }}>
        Tag
      </button>
      <select
        className="ql-header"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option value="1"></option>
        <option value="2"></option>
        <option ></option>
      </select>
      <button className="ql-link"></button>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-blockquote"></button>
      <button className="ql-code-block"></button>
      <button className="ql-header" value={1}></button>
      <button className="ql-header" value={2}></button>
      <button className="ql-list" value={'ordered'}></button>
      <button className="ql-list" value={'bullet'}></button>
      <button className="ql-script" value={'sub'}></button>
      <button className="ql-script" value={'super'}></button>
      <button className="ql-indent" value={'-1'}></button>
      <button className="ql-indent" value={'+1'}></button>
      <button className="ql-direction" value={'rtl'}></button>
      <select
        className="ql-size"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option value="small"></option>
        <option ></option>
        <option value="large"></option>
        <option value="huge"></option>
      </select>
      <select
        className="ql-color"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option value="#e60000"></option>
        <option value="#ff9900"></option>
        <option value="#ffff00"></option>
      </select>
      <select
        className="ql-background"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option value="#e60000"></option>
        <option value="#ff9900"></option>
        <option value="#ffff00"></option>
      </select>
      <select
        className="ql-font"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option></option>
        <option value="serif"></option>
        <option value="monospace"></option>
      </select>
      <select
        className="ql-align"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
      <button className="ql-clean" ></button>
    </div>
  ), []);
  // 直接声明会有显示问题
  const modules = useMemo(() => ({
    // toolbar: toolbarOptions
    toolbar: {
      container: '#toolbar',
      handlers: {
        askAI: askAI,
        tag: tag
      },
    },
  }), []);
  return (
    <div>
      <button style={{ display: 'none' }} onClick={askAIHandle} ref={AIBtnRef}></button>
      <button style={{ display: 'none' }} onClick={tagHandle} ref={tagBtnRef}></button>
      <Modal
        title=""
        open={askOpen}
        width={400}
        closeIcon={false}
        onCancel={() => setAskOpen(false)}
        afterClose={initAIDialog}
        style={{ top: `${toolTop + 180}px` }}
        footer={askLock?<Button disabled={isAnswering} type="link" onClick={insertAnswer2Editor}>插入下方<SelectOutlined /></Button>:<Button type="link" onClick={submitQuestion}>提问<SendOutlined /></Button>}
        mask={false}
      >
        <TextArea
          disabled={askLock}
          placeholder="问问AI..." value={questionContent} onChange={e => setQuestionContent(e.target.value)}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
        {answerContent?<TextArea
          readOnly={true}
          value={answerContent} 
          autoSize={{ minRows: 3, maxRows: 5 }}
        />:<></>}
      </Modal>
      <Modal
        title=""
        open={tagOpen}
        width={400}
        closeIcon={false}
        onCancel={() => setTagOpen(false)}
        afterClose={initTagDialog}
        footer={<Button type="link" onClick={submitTag}>保存<CheckOutlined /></Button>}
        mask={false}
        wrapClassName={'wrap-container'}
      >
        <TextArea
          placeholder="写下标记内容" value={tagContent} onChange={e => setTagContent(e.target.value)}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Modal>
      <CustomToolbar style={{ display: showTool ? 'block' : 'none', top: `${toolTop + 60}px` }} />
      <ReactQuill theme="snow"
        id='editor'
        ref={editorRef}
        value={mainContent}
        onChange={e => editorStateChange(e)}
        onBlur={editorBlur}
        modules={modules}
        placeholder={'Press ‘space’ for AI…'}
      />
    </div>
  )
}