import React from 'react'
import wx from '@/static/images/wx.png'
import { Image } from 'antd'
export default function Wechat() {
  return (
    <div className='m-g-b-24'>
      <div className='flex-row'>
        <Image src={wx} preview={false} width={20} rootClassName='m-g-r-5 pointer'></Image>
        <div className='pointer text-line-1'>请使用微信登录</div>
      </div>
    </div>
  )
}
