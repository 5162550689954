import './index.scss'
import Category from './components/category'
import Wechat from './components/wechat'
function LeftSide() {
  return (
    <div className='left-side'>
        <Wechat></Wechat>
        <Category></Category>
    </div>
  )
}

export default LeftSide