import { request } from './index'

interface PageInfo {
  pageNo:number,
  pageSize:number
}
interface categoryParams {
  pageInfo:PageInfo,
  uid: number
}
/**
 *  获取类目列表
 * @param categoryParams
 * 
 * @returns 
 */
export const GetCategoryList = <T>(params: categoryParams) => request.post<T>('/category/list', params, {timeout: 15000});

export const UpdateCategory = <T>(params: Category) => request.post<T>('/category/update_by_id', params, {timeout: 15000});