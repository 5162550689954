import { useState, useCallback } from "react";

const useToggleTags = (initialState = false): [Boolean, () => void] => {
  const [isOpenTags, setIsOpenTags] = useState(initialState)

  const toggleTags = useCallback(() => {
    setIsOpenTags(prevState => !prevState)
  }, [])
  
  return [isOpenTags, toggleTags]
}

export default useToggleTags