import React, { useCallback, useEffect, useRef, useState } from 'react'
import Input from 'antd/es/input/Input'
import type { InputRef } from 'antd';
import { DeleteOutlined,EditOutlined,FileAddOutlined } from '@ant-design/icons'
import { UpdateCategory } from '../../../../../../request/data';

export default function CategoryItem({category,className='',updateItem,refreshList}:{category:Category,className?:string,updateItem:Function,refreshList:Function}) {
  const [isEdit,setEdit] = useState(false)
  const  inputRef = useRef<InputRef>(null);
  useEffect(()=>{
    window.addEventListener('click',initEventHandler)
    return ()=>{
      window.removeEventListener('click',initEventHandler)
    }
  },[])
  useEffect(()=>{
    if(isEdit&&inputRef){
      const inpuDom = inputRef.current 
      inpuDom&&inpuDom.focus()
    }
  },[isEdit])
  const editActive = ((e:React.MouseEvent<HTMLDivElement>,type:'del'|'edit'|'add')=>{
    e.stopPropagation()
    console.log('edit')
    switch (type){
      case 'edit':
        setEdit(true)
        break;
      case 'add':
        break;
      case 'del':
        const newCategory:Category = {
          ...category,
          isDeleted:1,
        }
        updateTitleHandle(newCategory)
        break;
      default:
        break
    }
  })
  const updateTitle = (e:React.FocusEvent<HTMLInputElement>) =>{
    if(category.title !== e.target.value){
      const newCategory:Category = {
        ...category,
        title:e.target.value,
      }
      console.log('change?')
      updateTitleHandle(newCategory)
    }
    setEdit(false)
  }
  const updateTitleHandle = async (newCategory:Category) =>{
    const data = await UpdateCategory(newCategory)
    refreshList()
    // updateItem(newCategory)
    console.log('data:',data)
  }
  const initEventHandler = () =>{
    if(isEdit){
      console.log('eee')
      setEdit(false)
    }
  }
  return (
    <div className={`category-item m-g-b-30 ${className}`}>
      <div className="category-header m-g-b-14 flex-row-space-between">
        <div className="category-title text-line-1 f-18 f-600 semibold">
          {isEdit?<Input defaultValue={category.title} ref={inputRef} onClick={e=>e.stopPropagation()} onBlur={updateTitle}/>:
        category.title}</div>
        <div className="category-btns flex-row-space-around">
          <DeleteOutlined className='m-g-r-12 w-18' onClick={(e:React.MouseEvent<HTMLDivElement>)=>editActive(e,'del')}  style={category.deleteable?{color:'#000000',cursor:'pointer'}:{color:'#C9CED4',cursor:'not-allowed'}}></DeleteOutlined>
          <EditOutlined className='m-g-r-12 w-18' onClick={(e:React.MouseEvent<HTMLDivElement>)=>editActive(e,'edit')} style={category.editable?{color:'#000000',cursor:'pointer'}:{color:'#C9CED4',cursor:'not-allowed'}}></EditOutlined>
          <FileAddOutlined className='m-g-r-12 w-18' onClick={(e:React.MouseEvent<HTMLDivElement>)=>editActive(e,'add')}  style={category.addable?{color:'#000000',cursor:'pointer'}:{color:'#C9CED4',cursor:'not-allowed'}}></FileAddOutlined>
        </div>
      </div>
      <div className="category-list p-d-b-20 border-bottom">
        {category?.list?.map((note:Note,index:Number)=><div className='text-line-1 m-g-b-14 pointer'key={`note-item-${note?.id}-${index}`}>{note.title}</div>)}
      </div>
    </div>
  )
}
