import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import './index.scss'
import CategoryItem from './components/categoryItem'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import useAxios from "../../../../hooks/request";
import { GetCategoryList } from "../../../../request/data";
import useLoading from "../../../../hooks/useLoading";
import eventBus from "../../../../eventBus";
export default function Category() {
  let timer: NodeJS.Timeout | null = null;
  const categoryListRef = useRef<HTMLDivElement>(null)
  const [pageNo, setPageNo] = useState(1)
  const pageSize: number = 5
  const [isLoading, setLoading] = useLoading(false)
  const [bakCategory,setBakCategory] = useState<CategoryList>({ list: [], total: 0 })
  const [category, setCategory] = useState<CategoryList>({ list: [], total: 0 })

  useEffect(() => {
    return () => {
      setPageNo(1)
      setCategory({
        list: [],
        total: 0
      })

    }
  }, [])
  useEffect(() => {
    !isLoading&&getCategoryList()
  }, [pageNo])
  useEffect(() => {
  }, [category])
  useEffect(()=>{
  },[bakCategory])
  const initData = () =>{
    setCategory({ list: [], total: 0 })
    setBakCategory({ list: [], total: 0 })
    setPageNo(1)
    !isLoading&&getCategoryList(true)
  }
  const createCategory = () =>{
    eventBus.emit('startCreateCategory')
  }
  const getCategoryList = async (isFresh?:boolean) => {
    setLoading(true)
    setBakCategory(pre=>{
      return isFresh?{
        total:0,
        list:[]
      }:{
        total:pre.total+category.total,
        list:[...pre.list,...category.list]
      }
    })
      setCategory({
        list: [],
        total: 0
      })
    const data: CategoryList = await GetCategoryList({
      pageInfo: {
        pageNo:isFresh?1:pageNo,
        pageSize
      },
      uid: 0
    })
    setLoading(false)
    const categoryList:CategoryList = {
      total:data.list.filter(i=>`${i.isDeleted}`===`0`).length,
      list:data.list.filter(i=>`${i.isDeleted}`===`0`)
    }
    categoryList.list.forEach(item=>{
      item.addable = true;
      item.deleteable = true;
      item.editable = true
      item.list = [
        {
          uid:1231,
          isDeleted:true,
          description:'324243',
          title:'note1111',
        },
        {
          uid:1232,
          isDeleted:true,
          description:'324243',
          title:'note22222',
        },
        {
          uid:1233,
          isDeleted:true,
          description:'324243',
          title:'note3333',
        },
      ]
    })
    setCategory(categoryList)
  }
  const refreshList = () =>{
    initData()
  }
  const updateItem = ({data,index,list}:{data:Category,index:number,list:Category[]}):void =>{
    // list[index] = {
    //   ...list[index],
    //   ...data
    // }
    // setBakCategory(bakCategory)
    // setCategory(category)
    // console.log('list:',list)

    // console.log('bak:',bakCategory)
    // console.log('cate:',category)
  }
  const scrollHandler = (e: Event) => {
    timer && clearInterval(timer)
    timer = setTimeout(() => {
      const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLDivElement;
      console.log(category)
      console.log('scrollTop:',scrollTop)
      console.log('scrollHeight:',scrollHeight)
      console.log('clientHeight:',clientHeight)
      if ((scrollTop + clientHeight+1 >= scrollHeight)&&!isLoading) {
      console.log('bakbak:',bakCategory)
        if(category.total){
          setPageNo(pre => {
            return pre + 1
          });
          (e.target as HTMLDivElement).scrollTop = scrollTop - 15
        }
        // 滚动到底部的逻辑
        console.log('Scroll to bottom!');
      }
    }, 1000)
  }
  useEffect(() => {
    const categoryList = categoryListRef.current
    if (categoryList) {
      categoryList.addEventListener('scroll', scrollHandler)
    }
    return () => {
      if (categoryList) {
        categoryList.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [scrollHandler])
  return (
    <div>
      <div className="m-g-b-27">
        <Button className="w-98 h-32 p-d-l-10 f-15 silght" onClick={createCategory} >新建分类<PlusOutlined className="m-g-l-4" /></Button>
      </div>
      <div className="category-list" ref={categoryListRef}>
        {bakCategory?.list?.map((category: Category, index: number,list:Category[]) => <CategoryItem category={category} refreshList={refreshList} updateItem={(newCategory:Category)=>updateItem({data:newCategory,index:index,list:list})} className="bak" key={`category-item-${category?.id}-${index}`}></CategoryItem>)}
        {category?.list?.map((category: Category, index: number,list:Category[]) => <CategoryItem category={category} refreshList={refreshList} updateItem={(newCategory:Category)=>updateItem({data:newCategory,index:index,list:list})}  key={`category-item-${category?.id}-${index}`}></CategoryItem>)}
        {isLoading ? <LoadingOutlined style={{lineHeight:'60px',paddingBottom:'30px'}}/> : category?.total?<></>:<div>没有更多了</div>}
      </div>
    </div>
  )
}
