import { useState, useCallback } from "react";

const useToggleMenu = (initialState = false): [Boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState)

  const toggleMenu = useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [])
  
  return [isOpen, toggleMenu]
}

export default useToggleMenu